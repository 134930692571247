import PropTypes from 'prop-types'
import React from 'react'
import logo from '../images/harmeslogo-transparent-100x134.png'

const Header = (props) => (
  <header id="header" style={props.timeout ? { display: 'none' } : {}}>
    <div className="logo">
      {/* <span className="icon fa-diamond"></span> */}
      {/* <img src={logo} alt="" /> */}
    </div>
    <div className="content">
      <div className="inner">
        <h1>Paul Harmes Osteopathy</h1>
        <p>
          <b>The Charterhouse Clinic</b>
          <br />
          98 Crawford Street
          <br />
          London
          <br />
          W1H 2HL
        </p>
      </div>
    </div>
    <nav>
      <ul>
        <li>
          <button
            onClick={() => {
              props.onOpenArticle('intro')
            }}
          >
            Intro
          </button>
        </li>
        <li>
          <button
            onClick={() => {
              props.onOpenArticle('treatments')
            }}
          >
            Treatments
          </button>
        </li>
        <li>
          <button
            onClick={() => {
              props.onOpenArticle('about')
            }}
          >
            About
          </button>
        </li>
        <li>
          <button
            onClick={() => {
              props.onOpenArticle('price')
            }}
          >
            Price and Booking
          </button>
        </li>
      </ul>
    </nav>
  </header>
)

Header.propTypes = {
  onOpenArticle: PropTypes.func,
  timeout: PropTypes.bool,
}

export default Header
