import PropTypes from 'prop-types'
import React from 'react'
import pic01 from '../images/pic01.jpg'
import pic02 from '../images/pic02.jpg'
import pic03 from '../images/pic03.jpg'
import harmeslogoHR from '../images/harmeslogoHR.jpg'

class Main extends React.Component {
  render() {
    let close = (
      <div
        className="close"
        onClick={() => {
          this.props.onCloseArticle()
        }}
      ></div>
    )

    return (
      <div
        ref={this.props.setWrapperRef}
        id="main"
        style={this.props.timeout ? { display: 'flex' } : { display: 'none' }}
      >
        <article
          id="intro"
          className={`${this.props.article === 'intro' ? 'active' : ''} ${
            this.props.articleTimeout ? 'timeout' : ''
          }`}
          style={{ display: 'none' }}
        >
          <h2 className="major">Intro</h2>
          <span className="image main">
            <img src={pic01} alt="" />
          </span>
          <h3 className="major">What is Osteopathy?</h3>
          <p>
            Osteopathy is a holistic system of diagnosis and treatment for a
            wide range of medical conditions. It works on the principle that the
            well-being of an individual relies on the optimal function of all
            the body’s structures, as well as carefully considering the effect
            of environmental and psycho-social factors to health
          </p>
          <p>
            Treatment is a hands on therapy, using palpation, soft tissue
            massage, stretching and careful joint manipulation. This aims to
            reduce muscle tension, restore joint mobility, and enhance blood
            flow and nerve supply to tissues. Diagnosis is reached through
            thorough health screening and appropriate medical examination.
          </p>
          <h3 className="major">How can Osteopathy help?</h3>
          <p>Osteopathy primarily treats conditions such as:</p>

          <ul>
            <li>Back pain</li>
            <li>Neck and shoulder pain</li>
            <li>Sciatica and slipped discs</li>
            <li>Stress</li>
            <li>Headaches and migraines</li>
            <li>Repetitive strain injuries (RSI)</li>
            <li>Arthritic pain</li>
            <li>Sports injuries</li>
            <li>Back pain during and after pregnancy</li>
            <li>Poor posture</li>
          </ul>
          {close}
        </article>
        <article
          id="treatments"
          className={`${this.props.article === 'treatments' ? 'active' : ''} ${
            this.props.articleTimeout ? 'timeout' : ''
          }`}
          style={{ display: 'none' }}
        >
          <h2 className="major">Our Treatments</h2>
          <span className="image main">
            <img src={pic02} alt="" />
          </span>
          <h3 className="major">What to expect from treatment</h3>
          <p>
            Treatment always starts with a case history and examination. This is
            followed by a postural assessment, and any orthopaedic,
            cardiovascular or neurological testing as appropriate. This allows a
            thorough diagnosis, and helps to screen each patient for any
            underlying health problems.{' '}
          </p>
          <p>
            Each treatment is tailored to the individual and aims to educate the
            patient, helping them understand their pain and how to manage it
            effectively.
          </p>
          <p>
            Loose, comfortable clothing is ideal attire during the treatment.
          </p>
          <h3 className="major">Back Pain and Neck Pain</h3>
          <p>
            Back and neck pain are very common, and affect most people at some
            point in their lives. Common causes of pain are poor posture,
            lifting awkwardly, or trauma. Osteopathy works well for these types
            of pain because the osteopath will always look for the most logical
            solution. This usually involves effective and simple hands on
            treatment, followed by rehabilitative exercises and sound management
            advice.
          </p>
          <p>
            There is always help at hand for you, whether your pain is acute or
            chronic.
          </p>
          <h3 className="major">Stress and Tension Headaches</h3>
          <p>
            Stress affects everybody. It is part of everyday life, and can have
            varying degrees of affect upon our wellbeing. While most of us see
            stress as an outside force, there is another way of looking at it.
            When stressed, the body strives to increase its intake of oxygen by
            altering the way it breathes. So instead of just relying on the
            diaphragm and the elastic recoil of the thorax, it engages the
            accessory muscles of breathing in the neck and shoulders. Rather
            than allowing the diaphragm to descend and draw air into the lungs,
            the body is forcibly dragging the ribs into elevation to pull air
            in!
          </p>
          <p>
            As stress becomes more chronic, these muscles increase in tone,
            which actually reduces the effectiveness of the diaphragm and works
            against thoracic recoil. This can affect everything from mood to
            posture, and explains why stress causes neck and shoulder pain, as
            well as headaches, dizziness, hyperventilation and anxiety.
          </p>
          <p>
            Treatment combines head, neck and shoulder massage to ease muscle
            tension, and gentle manipulation to all the joints in the upper
            thorax to ensure freedom of movement in the spine and ribs. Simple
            but effective breathing techniques and home exercises also help.
          </p>
          <h3 className="major">Corporate Bookings</h3>
          <p>Looking after the back bone of your business.</p>
          <p>
            We are always open to working closely with companies that recognise
            the huge impact that sickness absence has on their business.
            According to the Office for National Statistics, the top four causes
            of sickness absence are 'back pain', 'neck and upper limb pain',
            'other musculoskeletal problems', and 'stress and anxiety'.
          </p>
          <p>
            There is no need for your business not to have a fast link to good
            care. We can provide a number of suggestions and treatment packages
            to help keep your staff fit for work, happy, and productive.
          </p>
          {close}
        </article>

        <article
          id="about"
          className={`${this.props.article === 'about' ? 'active' : ''} ${
            this.props.articleTimeout ? 'timeout' : ''
          }`}
          style={{ display: 'none' }}
        >
          <h2 className="major">About</h2>
          <span className="image main">
            {/* <img src={pic03} alt="" /> */}
            {/* <img src={harmeslogoHR} alt="" style={{ width: '100px' }} /> */}

            <img
              src={harmeslogoHR}
              alt=""
              style={{
                width: '40%',
                marginLeft: 'auto',
                marginRight: 'auto',
                paddingTop: '20px',
                paddingBottom: '20px',
                borderRadius: '10%',
              }}
            />
          </span>
          <h3 className="major">Location</h3>
          <p>
            <p>
              Paul works from The Charterhouse Clinic, situated in the heart of
              Marylebone.
            </p>
            <p>
              The Charterhouse Clinic is a calming and therapeutic environment,
              housing some of the most dedicated and most well established
              therapists in London.
            </p>
            <p>
              The clinic is a short walk from the Marylebone, Edgware Road and
              Marble Arch tube stations, and for those choosing to travel by
              car, there is plenty of street parking nearby.
            </p>
          </p>
          <h3 className="major">Bio</h3>
          <h4 class="lead">Paul Harmes M.Ost</h4>
          <p>
            Paul trained for five years at the British School of Osteopathy,
            achieving the Master of Osteopathy degree. This is one of the most
            challenging medical courses available in the UK. During his time
            there, Paul worked extensively in the Sports Clinic, treating a wide
            range of sporting injuries, as well as working in various other
            clinics dedicated to children, the elderly, HIV sufferers and
            expectant mothers.
          </p>
          <p>
            Paul has worked for several years in Marylebone, treating high level
            athletes, celebrities and world leaders in an atmosphere of utmost
            discretion.
          </p>
          <p>
            All osteopaths are registered and regulated by the General
            Osteopathic Council (GOsC). Osteopathy was recognised as a
            profession by parliament with the 1993 Osteopaths Act, and the
            formation of the GOsC.
          </p>
          <p>
            <a
              href="http://www.osteopathy.org.uk"
              target="blank"
              title="Osteopathy - PH Osteopathy"
            >
              <button class="btn btn-danger">www.osteopathy.org.uk</button>
            </a>
          </p>
          {close}
        </article>

        <article
          id="price"
          className={`${this.props.article === 'price' ? 'active' : ''} ${
            this.props.articleTimeout ? 'timeout' : ''
          }`}
          style={{ display: 'none' }}
        >
          <h2 className="major">Price and Booking</h2>
          <h3 className="major">Price</h3>
          <div className="treatment">30 Minute Treatment</div>
          <div className="price">£72</div>
          <br />
          <div className="treatment">60 Minute Treatment</div>
          <div className="price">£95</div>
          <br />
          <h3 className="major">Booking</h3>
          <p>
            To book please fill out the below form or contact me on the
            following:
            <br />
            <br />
            Email: <a href="mailto:info@phosteo.com">info@phosteo.com</a>
            <br />
            Clinic: <a href="tel:+44 (0)207 723 7084">+44 (0)207 723 7084</a>
            <br />
            Mobile: <a href="tel:+44 (0)7786 634885">+44 (0)7786 634885</a>
          </p>
          {/*
          <form method="post" action="#">
            <div className="field half first">
              <label htmlFor="name">Name</label>
              <input type="text" name="name" id="name" />
            </div>
            <div className="field half">
              <label htmlFor="email">Email</label>
              <input type="text" name="email" id="email" />
            </div>
            <div className="field">
              <label htmlFor="message">Message</label>
              <textarea name="message" id="message" rows="4"></textarea>
            </div>
            <ul className="actions">
              <li>
                <input type="submit" value="Send Message" className="special" />
              </li>
              <li>
                <input type="reset" value="Reset" />
              </li>
            </ul>
          </form>
          <ul className="icons">
            <li>
              <a
                href="https://twitter.com/HuntaroSan"
                className="icon fa-twitter"
              >
                <span className="label">Twitter</span>
              </a>
            </li>
            <li>
              <a href="https://codebushi.com" className="icon fa-facebook">
                <span className="label">Facebook</span>
              </a>
            </li>
            <li>
              <a href="https://codebushi.com" className="icon fa-instagram">
                <span className="label">Instagram</span>
              </a>
            </li>
            <li>
              <a
                href="https://www.linkedin.com/in/paul-harmes-75a46a47/"
                className="icon fa-linkedin"
              >
                <span className="label">LinkedIn</span>
              </a>
            </li>
          </ul> */}
          {close}
        </article>

        {/* <article
          id="price"
          className={`${this.props.article === 'price' ? 'active' : ''} ${
            this.props.articleTimeout ? 'timeout' : ''
          }`}
          style={{ display: 'none' }}
        >
          <h2 className="major">Price and Booking</h2>
          <form method="post" action="#">
            <div className="field half first">
              <label htmlFor="name">Name</label>
              <input type="text" name="name" id="name" />
            </div>
            <div className="field half">
              <label htmlFor="email">Email</label>
              <input type="text" name="email" id="email" />
            </div>
            <div className="field">
              <label htmlFor="message">Message</label>
              <textarea name="message" id="message" rows="4"></textarea>
            </div>
            <ul className="actions">
              <li>
                <input type="submit" value="Send Message" className="special" />
              </li>
              <li>
                <input type="reset" value="Reset" />
              </li>
            </ul>
          </form>
          <ul className="icons">
            <li>
              <a
                href="https://twitter.com/HuntaroSan"
                className="icon fa-twitter"
              >
                <span className="label">Twitter</span>
              </a>
            </li>
            <li>
              <a href="https://codebushi.com" className="icon fa-facebook">
                <span className="label">Facebook</span>
              </a>
            </li>
            <li>
              <a href="https://codebushi.com" className="icon fa-instagram">
                <span className="label">Instagram</span>
              </a>
            </li>
            <li>
              <a
                href="https://github.com/codebushi/gatsby-starter-dimension"
                className="icon fa-github"
              >
                <span className="label">GitHub</span>
              </a>
            </li>
          </ul>
          {close}
        </article> */}
      </div>
    )
  }
}

Main.propTypes = {
  route: PropTypes.object,
  article: PropTypes.string,
  articleTimeout: PropTypes.bool,
  onCloseArticle: PropTypes.func,
  timeout: PropTypes.bool,
  setWrapperRef: PropTypes.func.isRequired,
}

export default Main
